import React from 'react';
import logo from '../images/justin_yu_logo.png';
import { Draggable } from "./Draggable";
import moveicon from "../images/iconmonstr-cursor-23-240.png";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import DocumentMeta from 'react-document-meta';

const Header = () => {
	const meta = {
		title: 'Justin Yu - Front-End Web Developer',
		description: "Justin Yu's web developer portfolio website featuring past projects.",
		meta: {
			charset: 'utf-8',
			name: {
				keywords: 'react,meta,document,html,tags'
			}
		}
	}
	return (
		
		<header id="scroll-intro">
			<DocumentMeta {...meta} />
			<div className='logo'>
				<Link to ="/"><img className="logo" src={logo} alt='logo' /></Link>
			</div>
			<Draggable
				initialPos={window.innerWidth > 1200 
					? { x: window.innerWidth*0.07, y: window.innerHeight*0.9 } 
					: window.innerWidth > 600 
					? { x: window.innerWidth*0.05, y: window.innerHeight*0.90 } 
					: { x: window.innerWidth*0.03, y: window.innerHeight*0.90 }}
			>
				<img src={moveicon} alt='move-icon' className='move-icon'></img>
				<HashLink smooth to ="/#scroll-intro">Home</HashLink>
				<HashLink smooth to ="/#scroll-about">About</HashLink>
				<HashLink smooth to ="/#scroll-projects" className='scroll-anchor'>Projects</HashLink>
				<HashLink smooth to ="/#scroll-contact">Contact</HashLink>
				
			</Draggable>
			
		</header>
		
		
		
	);
};

export default Header;
