

function About()  {

  return (
    <>
      <section className="section-about">
          <h2 id='scroll-about'>About Me</h2>
          <p>
          Hi, I'm Justin, a Front-End Web Developer. I enjoy problem-solving, which is good since I tend to run into problems when coding.
          </p>
          <p>
          I’m a big collaborator, so while I enjoy problem-solving by myself, I also have fun observing how other people handle problems and learning from it. After all, everyone tends to have different specialties so there’s opportunities to learn everywhere.
          </p>
          <p>
          I don’t have any formal training, but I enjoy making small logos or small bits of art to include in my personal projects as a personal touch. It’s fun and relaxing and I think it helps direct me towards a theme.
          I like playing games of all kinds as a pastime, including board games. I also tend towards reading when I’m relaxing.
          </p>
          <h3>Skills and Tools</h3>
          <div className="skills-tools-wrapper">
            <div>
              <h3>Development</h3>
              <ul>
                <li>HTML5</li>
                <li>CSS</li>
                <li>SASS</li>
                <li>PHP</li>
                <li>jQuery</li>
                <li>React</li>
                <li>WordPress</li>
                <li>Shopify</li>
                <li>WooCommerce</li>
              </ul>
            </div>
            <div className="about-design">
              <h3>Design</h3>
              <ul>
                <li>Adobe XD</li>
                <li>Figma</li>
                <li>Adobe Illustrator</li>
                <li>Adobe Photoshop</li>
              </ul>
            </div>
            <div className="about-progress">
              <h3>In Progress</h3>
              <ul>
                <li>AngularJS</li>
              </ul>
            </div>
          </div>
          
      </section>
    </>
  )
}

export default About;
