import Intro from "../components/Intro";
import About from "../components/About";
import { Scroll } from "../components/Scroll";


function PageHome() {
	
	return (
		<>
			<Intro />
			<About />

			<Scroll/>
			
		</>
	);
}

export default PageHome;
