import { useRef, useState, useEffect } from "react";

export const Draggable = ({ children, initialPos }) => {
  const ref = useRef();
  const [state, setState] = useState({
    pos: initialPos,
    dragging: false,
    rel: {} // position relative to the cursor
  });
    const [width, setWidth]   = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

  useEffect(() => {
    document.addEventListener("pointermove", onMouseMove);
    document.addEventListener("pointerup", onMouseUp);

    console.log(state);

    return () => {
      document.removeEventListener("pointermove", onMouseMove);
      document.removeEventListener("pointerup", onMouseUp);
    };
  }, [state.dragging, state.attached]);

  // calculate relative position to the mouse and set dragging=true
  const onMouseDown = (e) => {
    // only left mouse button
    if (e.button !== 0) return;
    var pos = ref.current.getBoundingClientRect();
    const rel = {
      x: e.pageX - pos.left,
      y: e.pageY - pos.top
    };
    
    setState((p) => ({ ...p, dragging: true, rel }));
    e.stopPropagation();
    e.preventDefault();
  };
  const onMouseUp = (e) => {
    if (state.pos.x <= 10) {
        state.pos.x = 10;
    }
    if (state.pos.x >= (width - 120)) {
        state.pos.x = (width - 120);
    }
    if (state.pos.y <= 15) {
        state.pos.y = 15;
        state.pos.x = width*0.03;
        if (width > 600) {
            state.pos.x = width*0.05;
        }
        if (width > 1200) {
            state.pos.x = width*0.07;
        }
    }
    if (state.pos.y >= (height - 85)) {
        state.pos.y = (height - 85);
        state.pos.x = width*0.03;
        if (width > 600) {
            state.pos.x = width*0.05;
        }
        if (width > 1200) {
            state.pos.x = width*0.07;
            state.pos.y = (height - 95);
        }
    }
    setState((p) => ({ ...p, dragging: false }));
    e.stopPropagation();
    e.preventDefault();
  };
  const onMouseMove = (e) => {
    if (!state.dragging) return;
    const pos = {
      x: e.pageX - state.rel.x,
      y: e.pageY - state.rel.y
    };
    
    setState((p) => ({ ...p, pos }));
    e.stopPropagation();
    e.preventDefault();
  };
//   https://codesandbox.io/p/sandbox/draggable-component-react-mglg6d?file=%2Fsrc%2FApp.js
  return (
    <div
      ref={ref}
      onPointerDown={onMouseDown}
      onPointerUp={onMouseUp}
      className={ state.pos.y <= height - 300 && state.pos.y >= 60 ? "draggable-nav side-nav" : "draggable-nav"}
      style={{
        position: "fixed",
        left: state.pos.x,
        top: state.pos.y,
        zIndex: 10
      }}
    >
      {children}
    </div>
  );
};
