import rainbowdrop from '../images/rainbow-drop.webp';
import design from '../images/rainbowdrop-design.png';
import dev from '../images/rainbowdrop-dev.png';
import learning from '../images/rainbowdrop-learning.png'
import { useState } from 'react';

function RainbowDrop() {
	const [selected, setSelected] = useState('design');
    const buttonSelect = (e) => {
        let id = e.target.id;
        id === 'button-design' 
        ? setSelected('design')
        : id === 'button-dev'
        ? setSelected('development')
        : setSelected('learned')
    };
    function tabContent (designContent, devContent, learningContent) {
        return(selected === "design" ? designContent : selected === "development" ? devContent : learningContent);
    }
    const designText1 = "Before making the game, I spent a significant amount of time planning out how the game would work. The first draft consisted of a simple wave-based object-catching game. Raindrops would fall from the sky, and there would be colored buckets on the ground to catch them. By rotating the line of buckets, they would cycle. ";
    const designText2 = "I felt that the game was too easy, so I thought about what was possible for me to do, and came up with a wave-based object-catching game that would drop random patterns that matched the colored buckets on the ground and increasingly became more difficult. Theoretically, I could do it. In practice, I regretted making things more difficult for myself multiple times. Maybe even the whole time. After completing it, there are a bunch of things I could improve on to make it more consistent in difficulty, or remove bad luck scenarios. I learned a lot about making a game from a design point of view.";
	const devText1 = "I used setInterval() and setTimeout() very often for a lot of functions. The pause button, the waves, raindrops falling, all sorts of things relied on timing. Besides that, the raindrops took a lot of functions to work. I had to make a function to randomize colors of the buckets, which also governs the possible colors for the raindrops, grab the pattern of the colors of the buckets to serve as a base to start making randomly generated patterns by removing colors, and a function to decide a random number of raindrops to drop per wave. I had to rewrite some of these functions multiple times whenever I realized that it wasn't going to work with the rest of the code. It took a lot of patience to do it right.";
    const devText2 = "I also had to do my styling alongside the code since my raindrops relied on falling in certain columns that were built with css. I needed to add conditional classes so I could increase the number of columns in my grid as the game progressed to higher waves. Some fun unrelated functions were the save and display high scores function, and the save and display end of game stats function. The dev tools in chrome was exceedingly useful to get information on why things weren't working.";
    const learningText1 = "If I could do it again, I know which functions I would have to work on first before the rest. Specifically the function that governs the waves and raindrop falling gave me the most trouble and forced me to redo whole sections of code in order to redefine what a raindrop was, or find out a way to grab a property at a certain time. There were also some problems with rng, mostly either that it wasn't truly random, or that true random would sometimes cause the game to give the same pattern twice in a row which would make the game look broken.";
    const learningText2 = "While working, I realized that I could've spent more time in the planning stage. I decided to up the difficulty before starting to code, but I didn't update my plan accordingly. I think I could've made things far easier for myself if I wrote down my theoretical functions and what they would do so I could keep track of what parts of my project were connected. Keeping track in my head was a lot more difficult and caused me to make mistakes and waste a lot of time.";
    return (
		<>
        <div className="section-projects" id="rainbowdrop">
            <section>
                <h3>Rainbow Drop: a JavaScript Game</h3>
                <img src={rainbowdrop} alt="rainbow-drop-game"></img>
                <ul>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JavaScript</li>
                </ul>
                <p>A wave-based raindrop catching game built with JavaScript.</p>
                <div className="button-slide-wrapper cta-link">
                    <div className='button-slide'></div>
                    <a href='https://justiny.ca/Rainbow-Drop/'>Check out my project here!</a>
                </div>
            </section>
            <div className='tab-wrapper'>
                <button id='button-design' className={selected === "design" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Design</button>
                <button id='button-dev' className={selected === "development" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Development</button>
                <button id='button-learned' className={selected === "learned" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Learning Outcomes</button>
            </div>
            <section>
                <h3>{tabContent("Design and Planning", "Development and Issues", "Learning Outcomes")}</h3>
                <img className="img-secondary" src={tabContent(design, dev, learning)} alt={tabContent("vanlive-wireframe", "vanlive-footer", "vanlive-form")} loading="lazy" ></img>
                <p>{tabContent(designText1, devText1, learningText1)}</p>
                <p>{tabContent(designText2, devText2, learningText2)}</p>
            </section>
            
        </div>
		</>
	);
}

export default RainbowDrop;