import { useRef, useState, useEffect } from "react";
import Projects from "./Projects";

export const Scroll = () => {
    const ref = useRef();
    const [reveal, setRevealed] = useState(false);
    useEffect(() => {
        
        const observer = new IntersectionObserver((entries) => {
            const entry = entries[0];
            setRevealed(entry.isIntersecting);
            
          });
          observer.observe(ref.current);
        
        
    }, []);
    return (
        <div ref={ref} className={reveal === true ? "active reveal" : "reveal"}>
            <Projects />
        </div>
    );
};