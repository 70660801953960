import moviecat from '../images/moviecat.webp';
import design from '../images/moviecat-design.png';
import dev from '../images/moviecat-dev.png';
import learning from '../images/moviecat-learning.png'
import { useState } from 'react';

function MovieCat() {
	const [selected, setSelected] = useState('design');
    const buttonSelect = (e) => {
        let id = e.target.id;
        id === 'button-design' 
        ? setSelected('design')
        : id === 'button-dev'
        ? setSelected('development')
        : setSelected('learned')
    };
    function tabContent (designContent, devContent, learningContent) {
        return(selected === "design" ? designContent : selected === "development" ? devContent : learningContent);
    }
    const designText1 = "This website is modelled after my original design. After I designed a new logo, I decided to change the name. Since I already had a high-fidelity mockup, all the design decisions were already made and thus the work was mostly coding.";
    const designText2 = "There were some simplifications due to the difficulties we ran into, but the overall design is mostly the same. We had to decide on what extras we wanted to add, like animations, or a search bar.";
	const devText1 = "I worked on the sorting function, and I also chipped in to finish the favouriting feature as well as some other work that had to do with using the API. I learned a lot from pulling information from the API, and familiarizing myself with it allowed me to help other members of my team.";
    const devText2 = "I ran into problems while coding the sorting function, and that's when I learned how to code conditional classes. We mostly worked on our own for this project, so it was more difficult to keep our code compatible. We definitely would've benefitted from a couple of sessions where we just worked together since the difficult part was actually understanding how to do things and not the amount of code we had to write.";
    const learningText1 = "As with all code, you run into bugs a lot of the time. One that gave me quite a bit of trouble was one where certain movies didn't show up on the favourites page after using the favourite option. I couldn't figure it out until later when I was working on the sorting function and set each sort to pull from specific search options in the API. While testing it out, I noticed that the top-rated movies were not showing up on the favourites page at an uncommon rate compared to the other options. In fact, none of the newer movies were having this problem. ";
    const learningText2 = "That's when it clicked. I knew the API pulls had an upper limit to how many movies were pulled from the database, but it hadn't occurred to me that those movies weren't showing up on the favourites page because the API pull we were using was only pulling up to a thousand movies and some of the top-rated movies were too old to show up. It just goes to show that sometimes bug-fixing is like detective work.";
    return (
		<>
        <div className="section-projects" id="moviecat">
            <section>
                <h3>MovieCat: a React Movie Database</h3>
                <img src={moviecat} alt="moviecat-site"></img>
                <ul>
                    <li>HTML</li>
                    <li>SASS</li>
                    <li>React</li>
                </ul>
                <p>A movie database made with React using the TMDB API.</p>
                <div className="button-slide-wrapper cta-link">
                    <div className='button-slide'></div>
                    <a href='https://justiny.ca/moviecat/'>Check out my project here!</a>
                </div>
            </section>
            <div className='tab-wrapper'>
                <button id='button-design' className={selected === "design" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Design</button>
                <button id='button-dev' className={selected === "development" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Development</button>
                <button id='button-learned' className={selected === "learned" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Learning Outcomes</button>
            </div>
            <section>
                <h3>{tabContent("Design and Planning", "Development and Issues", "Learning Outcomes")}</h3>
                <img className="img-secondary" src={tabContent(design, dev, learning)} alt={tabContent("vanlive-wireframe", "vanlive-footer", "vanlive-form")} loading="lazy" ></img>
                <p>{tabContent(designText1, devText1, learningText1)}</p>
                <p>{tabContent(designText2, devText2, learningText2)}</p>
            </section>
            
        </div>
		</>
	);
}

export default MovieCat;