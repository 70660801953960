import vancouverlive from '../images/vanlive.webp';
import design from '../images/vancouverlive-design.png';
import dev from '../images/vanlive-dev.png';
import learning from '../images/vanlive-learning.png'
import { useState } from 'react';

function VancouverLive() {
	const [selected, setSelected] = useState('design');
    const buttonSelect = (e) => {
        let id = e.target.id;
        id === 'button-design' 
        ? setSelected('design')
        : id === 'button-dev'
        ? setSelected('development')
        : setSelected('learned')
    };
    function tabContent (designContent, devContent, learningContent) {
        return(selected === "design" ? designContent : selected === "development" ? devContent : learningContent);
    }
    const designText1 = "My team moved onto the design portion after nailing down the key project requirements and preliminary setup. Using the documents we worked on, I was able to complete a low fidelity wireframe for the site. I then passed it on to another member so they could create a high-fidelity wireframe from it.";
    const designText2 = "The process required me to research many websites in the same industry in order to learn from their layouts and design choices. I chose to implement a simple, UX-focused design made to put the spotlight on specific pieces of content, taking care not to overwhelm visitors.";
	const devText1 = "We went over the content inventory and decided on some preliminary tasks before we got into the nitty-gritty. The team went over the workflow process and we set up Trello to keep track of everyone's progress and updates. I started by coding the footer, which consisted of a lot of ACFs, so I took the opportunity to familiarize myself with some new tools like the Options Page";
    const devText2 = "It was quite interesting how the workflow evolved over time. As we moved onto styling, we often collaborated in pairs when we found an opportunity to reuse styles and classes for similar parts of the site. An important point was that we had to develop mixins ahead of time by predicting what parts had similar styling when looking at our design documents. We tried to lock things down with layouts before dealing with cosmetic styling.";
    const learningText1 = "Working in a team with a project that required us to work very closely together was slow-going at times, but it was worth it when we didn't need to waste time with failed merges, or any conflicts of opinions after a long period of work. It was like a well-oiled machine, we all knew how to do everything so all we had to do was stay on the same page as each other. Making sure we were all on track was key in increasing productivity, and reducing times where we had to wait for others to finish their parts.";
    const learningText2 = "I worked on implementing a few plugins into our site one of which being GravityForms. They were easy to implement into our code, and added a bunch of utility to our site. I will definitely make use of plugins whenever I can in the future.";
    return (
		<>
        <div className="section-projects" id="vancouver-live">
            <section>
                <h3>Vancouver Live: an e-commerce site</h3>
                <img src={vancouverlive} alt="vancouver-live-site"></img>
                <ul>
                    <li>WordPress</li>
                    <li>WooCommerce</li>
                </ul>
                <p>A multi-page e-commerce site built for an imaginary client for a music festival.</p>
                <div className="button-slide-wrapper cta-link">
                    <div className='button-slide'></div>
                    <a href='https://vancouverlive.bcitwebdeveloper.ca/'>Check out my project here!</a>
                </div>
            </section>
            <div className='tab-wrapper'>
                <button id='button-design' className={selected === "design" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Design</button>
                <button id='button-dev' className={selected === "development" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Development</button>
                <button id='button-learned' className={selected === "learned" ? "selected button-tab" : "button-tab"} onClick={buttonSelect}>Learning Outcomes</button>
            </div>
            <section>
                <h3>{tabContent("Design and Planning", "Development and Issues", "Learning Outcomes")}</h3>
                <img className="img-secondary" src={tabContent(design, dev, learning)} alt={tabContent("vanlive-wireframe", "vanlive-footer", "vanlive-form")} loading="lazy" ></img>
                <p>{tabContent(designText1, devText1, learningText1)}</p>
                <p>{tabContent(designText2, devText2, learningText2)}</p>
            </section>
        </div>
		</>
	);
}

export default VancouverLive;