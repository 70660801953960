import React from 'react';



function Intro()  {

  return (
    <>
      <section className='section-intro'>
          <p>"<span className='text-highlight'>Hello</span>, my name is <span className='text-highlight'>Justin Yu</span>, and I'm a <span className='text-highlight'>front-end web developer</span>.</p>
          <p className='emphasize'><span className='text-highlight'>Nice to meet you</span>."</p>
          <div className="button-slide-wrapper cta-link">
            <div className='button-slide'></div>
            <a href='/#scroll-projects' className='scroll-anchor'>Check out my projects!</a>
          </div>
          
      </section>
    </>
  )
}

export default Intro;
