import emailLogo from "../images/iconmonstr-email-10-240.png";
import linkedinLogo from "../images/iconmonstr-linkedin-4-240.png";
import githubLogo from "../images/iconmonstr-github-1-240.png";

function Footer()  {

	return (
	  <>
		<footer className="section-contact">
			<h2 id='scroll-contact'>Contact</h2>
			<div className="button-slide-wrapper cta-link">
            	<div className='button-slide'></div>
            	<a href='mailto:justinxyu1128@gmail.com'>Send me a message!</a>
          	</div>
			<nav>
				<a href="mailto:justinxyu1128@gmail.com"><img src={emailLogo} className="logo" alt="email-logo" loading="lazy"></img></a>
				<a href="https://www.linkedin.com/in/justinyu1128/"><img src={linkedinLogo} className="logo" alt="linkedin-logo" loading="lazy"></img></a>
				<a href="https://github.com/justinxyu1128"><img src={githubLogo} className="logo" alt="github-logo" loading="lazy"></img></a>
			</nav>
			<p className="footer-copyright">&copy; 2023 Justin Yu</p>
		</footer>
	  </>
	)
  }
  
  export default Footer;