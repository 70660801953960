

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/page-home';
import VancouverLive from './pages/page-vancouver-live';
import MovieCat from './pages/page-moviecat-site';
import RainbowDrop from './pages/page-rainbowdrop-game';
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
	return (
		<BrowserRouter>
			<Header />
			<main>
				<Routes>
					<Route path='/' index element={<Home />} />
					<Route path='/vancouverlive-woocommerce-site' element={<VancouverLive />} />
					<Route path='/moviecat-movie-database' element={<MovieCat />} />
					<Route path='/rainbowdrop-game' element={<RainbowDrop />} />
				</Routes>
			</main>
			<Footer />
		</BrowserRouter>
	)
}

export default App;
