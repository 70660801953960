import vancouverlive from '../images/vanlive.webp';
import rainbowdrop from '../images/rainbow-drop.webp';
import moviecat from '../images/moviecat.webp';
import { HashLink } from 'react-router-hash-link';

function Projects()  {

    return (
      <>
        <section className="section-projects">
            <h2 id='scroll-projects'>My Projects</h2>
            <div className='projects-wrapper'>
                <article className='article-vanlive'>
                    <HashLink to ="/vancouverlive-woocommerce-site#vancouver-live">
                        <h3>Vancouver Live: an e-commerce site</h3>
                        <img src={vancouverlive} alt="vancouver-live-site" loading="lazy" ></img>
                    </HashLink>
                    <ul>
                        <li>WordPress</li>
                        <li>WooCommerce</li>
                    </ul>
                    <p>A multi-page e-commerce site built for an imaginary client for a music festival.</p>
                </article>
                <article className='article-moviecat'>
                    <HashLink to ="/moviecat-movie-database#moviecat">
                        <h3>MovieCat: a React Movie Database</h3>
                        <img src={moviecat} alt="movie-cat-site" loading="lazy"></img>
                    </HashLink>
                    <ul>
                        <li>HTML</li>
                        <li>SASS</li>
                        <li>React</li>
                    </ul>
                    <p>A movie database made with React using the TMDB API.</p>
                </article>
                <article className='article-rainbowdrop'>
                    <HashLink to ="/rainbowdrop-game#rainbowdrop">
                        <h3>Rainbow Drop: a JavaScript Game</h3>
                        <img src={rainbowdrop} alt="rainbow-drop-game" loading="lazy"></img>
                    </HashLink>
                    <ul>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>JavaScript</li>
                    </ul>
                    <p>A wave-based raindrop catching game built with JavaScript.</p>
                </article>
            </div>
            
        </section>
      </>
    )
  }
  
  export default Projects;